/* Input Box */
.message-input-container {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  background: #1e272e;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  gap: 1rem;
  max-width: 100%;
}

/* Message Input */
.message-input {
  flex: 1;
  padding: 1rem;
  font-size: 1rem;
  background: #2d3436;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  outline: none;
  transition: border-color 0.2s ease;
}

.message-input::placeholder {
  color: #636e72;
}

.message-input:focus {
  border-color: #6c5ce7;
  box-shadow: 0 0 5px rgba(108, 92, 231, 0.5); /* Subtle focus effect */
}

/* Send Button */
.send-button {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  background: #6c5ce7;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
  font-weight: 500;
  min-width: 120px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.send-button:hover {
  background: #5e42cc; /* Slightly darker for hover */
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(108, 92, 231, 0.2); /* Subtle shadow on hover */
}

.send-button:disabled {
  background: #b2bec3;
  cursor: not-allowed;
  box-shadow: none;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .message-input-container {
    flex-direction: row; /* Keep it in a row layout instead of column */
    gap: 1rem; /* Add space between the elements */
    padding: 0 1rem; /* Adjust padding for smaller screens */
  }
  
  .send-button {
    width: auto; /* Allow the button to resize based on content */
    min-width: 80px; /* Ensure button doesn't shrink too much */
  }
}
