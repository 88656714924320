/* General Styling */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #1a1a1a; 
}

/* Chat Room Layout */
.room-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  backdrop-filter: blur(10px);
  overflow: hidden;
}

