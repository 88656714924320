/* Chat Box */
.chat-box {
  flex: 1;
  overflow-y: auto;
  padding: 1rem 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  scroll-behavior: smooth;
  background: linear-gradient(to bottom, #000, #1a1a1a);
  max-height: 80vh;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Message Styling */
.message {
  display: inline-block;
  padding: 1rem;
  border-radius: 12px;
  max-width: 80%;
  word-wrap: break-word;
  animation: fadeIn 0.3s ease;
  word-break: break-word;
  margin-bottom: 1rem;
}

/* Sent Message */
.message.bg-indigo-600 {
  background-color: #6c5ce7;
  align-self: flex-start;
  text-align: left; 
}

/* Received Message - Left-aligned */
.message.bg-gray-700 {
  background-color: #2d3436;
  align-self: flex-start;
  text-align: left;
}

/* Username */
.message-username {
  font-weight: bold;
  color: #ffffff;
}

/* Gender Badge */
.gender-badge {
  margin-left: 0.5rem;
  font-size: 0.75rem;
  padding: 0.2rem 0.4rem;
  border-radius: 3px;
  background: #4f5b66;
  color: #ffffff;
}

/* Timestamp */
.message-timestamp {
  font-size: 0.75rem;
  color: #a6c8ff;
  margin-top: 0.5rem;
  text-align: right;
  opacity: 0.7; /* Slightly faded for a subtle effect */
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
  .message {
    max-width: 90%; /* Allow more room on smaller screens */
  }
  .message-username {
    font-size: 1rem; /* Slightly larger font for better readability */
  }

  /* Adjust message alignment on smaller screens */
  .message.bg-indigo-600 {
    align-self: flex-end;
    text-align: right; 
  }

  .message.bg-gray-700 {
    align-self: flex-start;
    text-align: left; 
  }
}
