/* Header */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #1e272e;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    position: sticky;
    top: 0;
    z-index: 10;
    flex-wrap: nowrap; /* Ensure elements stay in one line */
  }
  
  /* Users Online */
  .users-online {
    font-size: 0.9rem;
    color: #74b9ff;
    flex: 0 1 auto;
    text-align: left;
    margin-right: auto;

  }
  
  /* Room Info */
  .room-info {
    font-size: 1.25rem;
    font-weight: bold;
    flex: 1 1 auto;
    text-align: center;
    color: #ffffff;
  }
  
  .room-info .room-name {
    color: #a29bfe;
  }
  
  /* Leave Room Button */
  .leave-room-button {
    background: #d63031;
    color: #ffffff;
    border: none;
    padding: 0.5rem 1.2rem;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
  }
  
  .leave-room-button:hover {
    background: #ff7675;
    transform: scale(1.05);
  }
  
/* Media Query for Small Screens (max-width: 768px) */
@media (max-width: 768px) {
    .header {
      flex-direction: column;
      align-items: center;  /* Align children horizontally in the center */
      justify-content: center; /* Ensure proper vertical alignment */
    }
  
    .users-online {
      text-align: center;
      margin-bottom: 0.5rem;
      width: 100%; /* Ensure it takes full width for proper centering */
    }
  
    .room-info {
      font-size: 1.1rem;
      margin: 0.5rem 0;
      text-align: center; /* Ensure it's centered */
    }
  
    .leave-room-button {
      margin-top: 0.5rem;
      align-self: center;
    }
  }
  
  