:root {
  --primary-color: #ffffff;
  --secondary-color: #dfe6e9;
  --accent-color: #6c5ce7; /* Light Purple */
  --button-hover: #74b9ff; /* Light Blue */
}

/* Hero Section */

.hero-content h1 span {
  color: var(--accent-color);
}

.hero-buttons button {
  background: var(--accent-color);
  color: var(--primary-color);
}

.hero-buttons button:hover {
  background: var(--button-hover);
  color: var(--primary-color);
}

/* Features Section */
.features-section h2 span {
  color: var(--accent-color);
}

.feature:hover {
  background: rgba(108, 92, 231, 0.2);
}

/* Testimonials Section */
.testimonials-section h2 {
  color: var(--accent-color);
}

.testimonial h4 {
  color: var(--accent-color);
}

/* Call-to-Action Section */
.cta-section {
  background: var(--accent-color);
  color: var(--primary-color);
}

.cta-section .primary-button {
  background: #0c2461;
  color: var(--primary-color);
}

.cta-section .primary-button:hover {
  background: var(--button-hover);
  color: var(--primary-color);
}

/* Footer Section */
.footer-section {
  background: #1e272e;
  color: var(--secondary-color);
}

.footer-links a:hover {
  color: var(--accent-color);
}
