/* Global Styles */

@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  font-family: 'Roboto', sans-serif;
  background: #fff;
  color: #000;
  height: 100vh;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  flex-direction: column;
}
